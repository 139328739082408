import { TeenyiconsDownSolid } from '@/assets/icons/comm/TeenyiconsDownSolid';
import { MingcuteTransferFill } from '@/assets/icons/funds/MingcuteTransferFill';
import { BaseFlex } from '@/components/base/baseFlex';
import FeaturesNumberInput from '@/components/features/featuresNumberInput';
import CacheEnum from '@/enums/cacheEnum';
import { AccountSelectPopup } from '@/pages/funds/convert/components/AccountSelectPopup';
import { CoinSelectPopup } from '@/pages/funds/convert/components/CoinSelectPopup';
import { MarketPriceEnterOrder } from '@/pages/funds/convert/components/MarketPriceEnterOrder';
import { useStateHooks } from '@/pages/funds/convert/useStateHooks';
import {
  getAllCoinList,
  getCoinTradeDetail,
  walletFundApi,
} from '@/services/api/requestApi';
import { useReq } from '@/services/net/request';
import RenderUtil from '@/utils/RenderUtil';
import { Button, CircularProgress } from '@nextui-org/react';
import { history, useModel } from '@umijs/max';
import { useUpdateEffect } from 'ahooks';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { animated, useSpring } from 'react-spring';
import { toast } from '@/layouts/components/ToastMessage';
import BaseSkeleton from '@/components/base/baseSkeleton';
import { useLocalStorage } from '@/hooks/useStorage';
import PageEnum from '@/enums/pageEnum';
import AddMoneyEntranceButtonModal from '@/pages/transaction/components/AddMoneyEntranceButtonModal';
import { AddAmount } from '@/assets/icons/comm/AddAmount';

/**
 * 闪兑
 */
export function MarketPriceView() {
  const { t } = useTranslation();

  const {
    toggle,
    setToggle,
    openAccount,
    setOpenAccount,
    open,
    setOpen,
    setCoinSelect,
    coinSelect,
  } = useStateHooks();

  const [params, setParams] = useState<any>({
    pageNo: 1,
    pageSize: 10,
  });

  const [defaultDirection, setDefaultDirection] = useState<any>('from');

  const { data: list = [] as any, loading: listLoading } = useReq(getAllCoinList, {
    onSuccess: () => {
    },
    onError: (e) => {
    },
  });

  const { y } = useSpring({
    to: { y: toggle ? 160 : 0 },
    config: { tension: 250, friction: 20 },
  });

  // 默认选择币种
  const [selected, setSelected] = useLocalStorage({
    key: CacheEnum.APP_SELECT_COIN_DATA,
    defaultValue:{
    from: {},
    reach: {},
  }});

  const [value, setValue] = useState<any>({
    from: '',
    reach: '',
  });

  const [progress, setProgress] = useState(0);

  // 展示可用
  const [showAvailable, setShowAvailable] = useState<any>({});
  const { data = {} as any, runAsync, loading: detailLoading } = useReq(() => walletFundApi(), {
    cacheKey: CacheEnum.APP_WALLETFUNDAPI,
  });

  // 下面 1btc = xxx 请求接口
  const [isFirstDetailLoading, setIsFirstDetailLoading] = useState(true);
  const {
    data: detailData = {} as any,
    runAsync: getCoinDetail,
    loading: coinDetailLoading,
  } = useReq((params: any) => getCoinTradeDetail(params), {
    manual: true,
    formatResult: (res: any) => {
      return res?.data ?? {};
    },
    onSuccess() {
      setIsFirstDetailLoading(false);
    },
  });

  // 输入框展示
  const { runAsync: getInputCoinDetail } = useReq(
    (params: any) => {
      if (params?.amount) {
        return getCoinTradeDetail(params);
      }
      return null;
    },
    {
      debounceWait: 500,
      manual: true,
      formatResult: (res: any) => {
        return res?.data ?? {};
      },
      onSuccess: (res: any) => {
        if (res) {
          // 最终能购买的数量
          let amount = 0;
          if (value?.from) {
            amount = res?.rate * value?.from - res?.fee;
          }
          setValue({
            ...value,
            reach: ![null,undefined].includes(res?.rate)?amount < 0 ? 0 : amount.toFixed(8):"",
          });
        }
      },
    },
  );

  useEffect(() => {
    const storedCoinData = window.localStorage.getItem(CacheEnum.APP_SELECT_COIN_DATA);
    const parsedCoinData = JSON.parse(storedCoinData || '{}');
    if (list?.length !== 0 && (!parsedCoinData?.from||(parsedCoinData?.from && !Object.values(parsedCoinData?.from)?.length))) {
      setSelected({
        // from: list[0],
        // reach: list[1],
        from: list.find((dt:any)=> dt.name ==="BTC"),
        reach: list.find((dt:any)=> dt.name ==="USDT"),
      });
    }
    if (list?.length !== 0 ){
        // 获取汇率
        getCoinDetail({
          sourceCoinId: parsedCoinData?.from?.id??list.find((dt:any)=> dt.name ==="BTC")?.id,
          targetCoinId: parsedCoinData?.reach?.id??list.find((dt:any)=> dt.name ==="USDT")?.id,
          amount: 1,
          type: 1,
        });
    }
  }, [list]);

  useUpdateEffect(() => {
    getCoinDetail({
      sourceCoinId: selected?.from?.id,
      targetCoinId: selected?.reach?.id,
      amount: 1,
      type: 1,
    });
    // 切换了币种重新计算目标币种

    getInputCoinDetail({
      sourceCoinId: selected?.from?.id,
      targetCoinId: selected?.reach?.id,
      amount: value?.from,
      type: 1,
    });
  }, [selected]);

  useEffect(() => {
    if (
      data?.walletList?.length !== 0 &&
      JSON.stringify(selected?.from) !== '{}'
    ) {
      const find = data?.walletList?.find(
        (item: any) => item?.name === selected?.from?.name,
      );
      setShowAvailable(find);
    }
  }, [data, selected]);

  useEffect(() => {
    if (detailData && Object.keys(detailData).length > 0) {
      const interval = setInterval(() => {
        setProgress((v) => (v >= 100 ? 0 : v + 1));
      }, 100);

      return () => clearInterval(interval);
    }
  }, [detailData]);

  useEffect(() => {
    if (progress === 100 && Object.keys(selected).length > 0) {
      getCoinDetail({
        sourceCoinId: selected?.from?.id,
        targetCoinId: selected?.reach?.id,
        amount: 1,
        type: 1,
      });
    }
  }, [progress]);

  // 检查小数位数

  const checkDecimalPlaces = (num: number | null): boolean => {
    if (!num) return false; // 如果值不存在，返回false

    const numStr = num.toString();
    const decimalIndex = numStr.indexOf('.');

    // 如果没有小数点，则小数位数为0
    if (decimalIndex === -1) return false;

    // 计算小数点后有多少位数字
    const decimalPlaces = numStr.length - decimalIndex - 1;

    return decimalPlaces > 8;
  };

  // 刷新钱包
  const { refreshWallet } = useModel('user');
  // 渲染右侧 下边
  const renderRight = useMemo(() => {
    return (
      <BaseFlex
        align="center"
        justify="between"
        flex={1}
        vertical
        className="bg-backgroundAuxiliaryColor pt-[32px] py-3 px-4 rounded-md min-h-[140px]"
      >
          <span className="w-full text-xs text-left text-auxiliaryTextColor">
            {toggle ? t('到') : t('从')}
          </span>
        <div className="w-full pt-2 flex items-center justify-between">
          <div className="flex-shrink-0">
            <BaseFlex
              align="center"
              justify="start"
              flex={1}
              onClick={() => {
                if (!toggle) {
                  setDefaultDirection('from');
                } else {
                  setDefaultDirection('reach');
                }
                setCoinSelect(true);
              }}
              className="w-full flex-shrink-0"
            >
              <img
                src={!toggle ? selected?.from?.icon : selected?.reach?.icon}
                width={20}
                alt=""
              />
              <span className="ml-1">
              {!toggle ? selected?.from?.alias : selected?.reach?.alias}
            </span>
              <TeenyiconsDownSolid className="text-xss  ml-2" />
            </BaseFlex>
          </div>

          <BaseFlex align="center" justify="end">
            <FeaturesNumberInput
              isClearable={false}
              className="!h-full"
              classNames={{
                input:
                  'text-right outline-none border-0 text-xl font-bold pr-0 text-titleColor',
                inputWrapper: 'border-0 shadow-none p-0',
              }}
              value={!toggle ? value?.from : value?.reach}
              onChange={(v: any) => {
                // 判断是否是数字
                if (isNaN(v)) return true;
                //判断是否是负数
                if (v < 0) return true;
                if (!toggle) {
                  setValue({
                    ...value,
                    from: v,
                  });
                  getInputCoinDetail({
                    sourceCoinId: selected?.from?.id,
                    targetCoinId: selected?.reach?.id,
                    amount: v,
                    type: 1,
                  });
                } else {
                  setValue({
                    ...value,
                    reach: v,
                  });
                  getInputCoinDetail({
                    sourceCoinId: selected?.from?.id,
                    targetCoinId: selected?.reach?.id,
                    amount: v,
                    type: 1,
                  });
                }
              }}
              placeholder="0.01 - 8000"
            />
          </BaseFlex>
        </div>
        {!toggle && (
          <BaseFlex align="center" justify="end" flex={1}>
            {checkDecimalPlaces(value?.from ?? '') && (
              <span className="text-errorColor text-xs mr-2">{t('失败')}</span>
            )}
            <div
              className="border-0 text-primary rounded-md px-2 py-1   min-w-10  text-xs cursor-pointer"
              onClick={() => {
                setValue({
                  ...value,
                  from: showAvailable?.availableBalance,
                });
                getInputCoinDetail({
                  sourceCoinId: selected?.from?.id,
                  targetCoinId: selected?.reach?.id,
                  amount: showAvailable?.availableBalance,
                  type: 1,
                });
              }}
            >
              {t('最大')}
            </div>
          </BaseFlex>
        )}
      </BaseFlex>
    );
  }, [toggle, selected, value, showAvailable]);

  // 渲染左侧
  const renderLeft = useMemo(() => {
    return (
      <BaseFlex
        align="center"
        flex={1}
        vertical
        className="bg-backgroundAuxiliaryColor pt-[32px] py-3 px-4 rounded-md min-h-[140px]"
      >
        <div className="w-full text-xs  text-left text-auxiliaryTextColor">
          {!toggle ? t('至') : t('从')}
        </div>

        <div className="w-full justify-between pt-2 flex items-center">
          <div className="flex-shrink-0">
            <BaseFlex
              align="center"
              justify="start"
              flex={1}
              onClick={() => {
                if (!toggle) {
                  setDefaultDirection('reach');
                } else {
                  setDefaultDirection('from');
                }
                setCoinSelect(true);
              }}
              className="w-full flex-shrink-0"
            >
              <img
                src={toggle ? selected?.from?.icon : selected?.reach?.icon}
                width={20}
              />
              <span className="ml-1 text-[14px]">
              {toggle ? selected?.from?.alias : selected?.reach?.alias}
            </span>
              <TeenyiconsDownSolid className="text-xss  ml-2" />
            </BaseFlex>
          </div>
          <div>
            <FeaturesNumberInput
              isClearable={false}
              className="!h-full"
              classNames={{
                input:
                  'text-right border-0 text-xl font-bold pr-0 text-titleColor',
                inputWrapper: 'border-0 shadow-none p-0',
              }}
              value={toggle ? value.from : value.reach}
              onChange={(v: any) => {
                // 判断是否是数字
                if (isNaN(v) || !v) return;
                if (!toggle) {
                  setValue({
                    ...value,
                    reach: v,
                  });
                  getInputCoinDetail({
                    sourceCoinId: selected?.from?.id,
                    targetCoinId: selected?.reach?.id,
                    amount: v,
                    type: 1,
                  });
                } else {
                  setValue({
                    ...value,
                    from: v,
                  });
                  getInputCoinDetail({
                    sourceCoinId: selected?.from?.id,
                    targetCoinId: selected?.reach?.id,
                    amount: v,
                    type: 1,
                  });
                }
              }}
              placeholder="0.01 - 8000"
            />
          </div>
        </div>
        <div className="flex justify-end w-full">
          {checkDecimalPlaces(value?.reach ?? '') && (
            <span className="text-errorColor text-xs mr-2">{t('失败')}</span>
          )}
        </div>
        {toggle && (
          <BaseFlex align="center" justify="end" flex={1}>
            <div
              className="border-0 text-primary rounded-md px-2 py-1 bg-tabBackground  min-w-10  text-xs"
              onClick={() => {
                setValue({
                  ...value,
                  from: showAvailable?.availableBalance,
                });
                getInputCoinDetail({
                  sourceCoinId: selected?.from?.id,
                  targetCoinId: selected?.reach?.id,
                  amount: showAvailable?.availableBalance,
                  type: 1,
                });
              }}
            >
              {t('最大')}
            </div>
          </BaseFlex>
        )}
      </BaseFlex>
    );
  }, [toggle, selected, value, showAvailable]);

  return (
    <>
      <div className="p-3">
        {
          !listLoading && <>
            <animated.div
              style={{
                width: '100%',
                transform: y.to((y) => `translateY(${y}px)`),
              }}
            >
              {renderRight}
            </animated.div>

            <BaseFlex
              align="center"
              justify="center"
              style={{ margin: '-10px', zIndex: '999' }}
            >
              <Button
                style={{ zIndex: '999' }}
                isIconOnly
                color="warning"
                variant="faded"
                aria-label="Take a photo"
                className="border-0 bg-background rounded-full"
                onClick={() => {
                  setToggle((prevState) => !prevState);
                  // 去除 =》清空输入框
                  // setValue({
                  //   from: '',
                  //   reach: '',
                  // });
                  setSelected({
                    from: selected?.reach,
                    reach: selected?.from,
                  });
                  getCoinDetail({
                    sourceCoinId: selected?.reach?.id,
                    targetCoinId: selected?.from?.id,
                    amount: 1,
                    type: 1,
                  });
                }}
              >
                <MingcuteTransferFill
                  className={`transition delay-150 ${
                    toggle ? 'rotate-90' : '-rotate-90'
                  } text-primary text-xl`}
                />
              </Button>
            </BaseFlex>

            <animated.div
              style={{
                width: '100%',
                transform: y.to((y) => `translateY(${-y}px)`),
              }}
            >
              {renderLeft}
            </animated.div>
          </>
        }

        <BaseSkeleton
          isLoaded={listLoading}
          className="relative  "
        >
          <div className=" bg-backgroundAuxiliaryColor mb-4 h-[142px] rounded-md "></div>
          <div
            className="flex justify-center items-center absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 bg-background w-[40px] h-[40px] rounded-full ">
            <MingcuteTransferFill
              className={`transition delay-150 ${
                toggle ? 'rotate-90' : '-rotate-90'
              } text-primary text-xl`}
            />
          </div>
          <div className=" bg-backgroundAuxiliaryColor mt-4 h-[142px] rounded-md "></div>
        </BaseSkeleton>


        {
          !detailLoading && <div className="flex items-center gap-2 text-auxiliaryTextColor text-xxs mt-6">
            <span>{t('可用')}</span>
            <span>
            {RenderUtil.FormatAmount(showAvailable?.availableBalance,8)}
              {showAvailable?.alias}
            </span>
            <AddMoneyEntranceButtonModal className="w-fit">
              <AddAmount />
            </AddMoneyEntranceButtonModal>
          </div>
        }

        <BaseSkeleton
          isLoaded={detailLoading}
          className="mt-6 w-[120px]"
        >
          <div className="bg-backgroundAuxiliaryColor  h-[14px] rounded-md "></div>
        </BaseSkeleton>

        {
          (!coinDetailLoading || !isFirstDetailLoading) && <BaseFlex align="center" flex={1} className="mt-2">
            <div className="flex items-center gap-2  text-auxiliaryTextColor text-xxs">
              <span> {t('汇率')}</span>
              {JSON.stringify(detailData) !== '{}' && (
                <span>
                {' '}
                  1{' ' + selected?.from?.alias} ={' '}
                  {RenderUtil.FormatAmount(detailData?.rate, 6) ?? 0}{' '}
                  {selected?.reach?.alias}
              </span>)}
            </div>
            <CircularProgress
              className="ml-2"
              size="sm"
              value={progress}
              classNames={{
                svg: 'w-4 h-4 drop-shadow-md',
                indicator: 'stroke-backContrastColor',
                track: 'stroke-backContrastColor/10',
                value: 'text-3xl font-semibold text-primary',
              }}
            />
          </BaseFlex>
        }

        <BaseSkeleton
          isLoaded={coinDetailLoading && isFirstDetailLoading}
          className="mt-2 w-[200px]"
        >
          <div className="bg-backgroundAuxiliaryColor  h-[16px] rounded-md "></div>
        </BaseSkeleton>


        <BaseFlex align="center" justify="center" flex={1}  vertical={true} className="mt-16 space-y-4">
          <Button
            className="text-md mainColorButton"
            radius="sm"
            onClick={() => {
              if (!value.from) return toast.warning(t('请输入数量'));
              if (value?.from <= '0') return toast.warning(t('请输入正确数量'));
              if (value?.reach <= '0' || !value?.reach)
                return toast.warning(t('闪兑到账数量不能为空或小于等于0'));
              if (showAvailable?.availableBalance < value?.from) {
                return toast.warning(t('可用金额不足'));
              } else {
                setOpen(true);
              }
            }}
          >
            {t('询价')}
          </Button>

        </BaseFlex>
      </div>

      {/* 确认下单 */}
      <MarketPriceEnterOrder
        defaultValue={{ ...selected, value }}
        open={open}
        onClose={() => setOpen(false)}
        onSuccess={() => {
          setOpen(false);
          setValue('');
          runAsync();
        }}
      />
      {/* 选币 */}
      <CoinSelectPopup
        walletList={data?.walletList}
        defaultValue={selected}
        setSelected={setSelected}
        isOpen={coinSelect}
        defaultDirection={defaultDirection}
        setDefaultDirection={setDefaultDirection}
        onClose={() => {
          setCoinSelect(false);
        }}
        onSelect={(v) => {
          refreshWallet();
          setCoinSelect(false);
          setSelected({ ...v });
          setValue({
            from: '',
            reach: '',
          });
        }}
      />
      <AccountSelectPopup
        isOpen={openAccount}
        onClose={() => setOpenAccount(false)}
      />
    </>
  );
}
