import { Key } from '@react-types/shared';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

export function useStateHooks() {
  const { t } = useTranslation();

  const [selectedTabs, setSelectedTabs] = useState<Key>('1');

  const [toggle, setToggle] = useState(false);

  // 打开确认订单
  const [open, setOpen] = useState(false);

  // 打开币种选择
  const [coinSelect, setCoinSelect] = useState(false);

  // 打开账户选择
  const [openAccount, setOpenAccount] = useState(false);

  const tabsList = [
    {
      text: t('市价'),
      value: 1,
    },
    {
      text: t('限价'),
      value: 2,
    },
  ];

  const TabsOnChange = (key: Key) => {
    setSelectedTabs(key);
  };

  return {
    selectedTabs,
    setSelectedTabs,
    tabsList,
    TabsOnChange,
    toggle,
    setToggle,
    open,
    setOpen,
    coinSelect,
    setCoinSelect,
    openAccount,
    setOpenAccount,
  };
}
