import { BaseFlex } from '@/components/base/baseFlex';
import BaseModal from '@/components/base/baseModal';
import { Button, Checkbox, cn } from '@nextui-org/react';
import { useTranslation } from 'react-i18next';

type AccountSelectPopupProps = any;

export function AccountSelectPopup(props: AccountSelectPopupProps) {
  const { t } = useTranslation();

  return (
    <BaseModal isOpen={props?.isOpen} onClose={props?.onClose}>
      <BaseFlex align="center" justify="center" flex={1}>
        <span className="text-auxiliaryTextColor">{t('可用')}</span>
      </BaseFlex>
      <BaseFlex align="center" justify="center" flex={1}>
        <span className="font-bold text-xl text-titleColor">0 FRONT</span>
      </BaseFlex>
      <BaseFlex align="center" justify="center" className="mt-4" flex={1}>
        <Checkbox
          aria-label={''}
          classNames={{
            base: cn(
              'w-full max-w-full',
              ' items-center justify-start',
              'cursor-pointer rounded-lg gap-2 p-4 border-1 border-auxiliaryTextColor',
              'data-[selected=true]:border-primary',
            ),
            label: 'w-full',
          }}
        >
          <BaseFlex align="start" justify="start" vertical flex={1}>
            <span>{t('现货钱包')}</span>
            <span>0 FRONT</span>
          </BaseFlex>
        </Checkbox>
      </BaseFlex>
      <BaseFlex align="center" justify="center" className="mt-8" flex={1}>
        <Checkbox
          aria-label={''}
          classNames={{
            base: cn(
              'w-full max-w-full',
              ' items-center justify-start',
              'cursor-pointer rounded-lg gap-2 p-4 border-1 border-auxiliaryTextColor',
              'data-[selected=true]:border-primary',
            ),
            label: 'w-full',
          }}
        >
          <BaseFlex align="start" justify="start" vertical flex={1}>
            <span>{t('资金钱包')}</span>
            <span>0 FRONT</span>
          </BaseFlex>
        </Checkbox>
      </BaseFlex>
      <BaseFlex align="center" justify="center" flex={1} className="mt-6">
        <Button className="text-background w-full bg-primary" radius="sm">
          {t('确认')}
        </Button>
      </BaseFlex>
    </BaseModal>
  );
}
