import { MaterialSymbolsAssignmentRounded } from '@/assets/icons/funds/MaterialSymbolsAssignmentRounded';
import { BaseFlex } from '@/components/base/baseFlex';
import BaseTopNav from '@/components/base/baseTopNav';
import { CompletedOrderOrderProcessEnum } from '@/enums/businessEnum';
import PageEnum from '@/enums/pageEnum';
import { MarketPriceView } from '@/pages/funds/convert/components/MarketPriceView';
import { history } from '@umijs/max';
import { useTranslation } from 'react-i18next';

export default function ConvertView() {
  const { t } = useTranslation();
  return (
    <div>
      <BaseTopNav
        title={t('闪兑')}
        rightNode={
          <BaseFlex align="center" justify="center">
            <MaterialSymbolsAssignmentRounded
              onClick={() =>
                history.push(
                  PageEnum.ORDER_HISTORY +
                    '?type=' +
                    CompletedOrderOrderProcessEnum.SWAP,
                )
              }
              className="py-2 px-4 box-content"
            />
          </BaseFlex>
        }
      />
      {/*<BaseFlex align="center" justify="start">*/}
      {/*  <BaseTabs*/}
      {/*    value={selectedTabs}*/}
      {/*    onChange={TabsOnChange}*/}
      {/*    options={tabsList}*/}
      {/*    tabsPropsProps={{*/}
      {/*      className: 'w-full',*/}
      {/*      classNames: {*/}
      {/*        tab: 'first:!pl-3',*/}
      {/*        tabList: 'pb-0',*/}
      {/*      },*/}
      {/*    }}*/}
      {/*  />*/}
      {/*</BaseFlex>*/}
      {/*{selectedTabs === '1' ? <MarketPriceView /> : <LimitView />}*/}
      <MarketPriceView />
    </div>
  );
}
