import right from '@/assets/img/funds/right.png';
import { XCard } from '@/components/base/baseCard';
import { BaseFlex } from '@/components/base/baseFlex';
import BaseInput from '@/components/base/baseInput';
import BaseModal from '@/components/base/baseModal';
import { getAllCoinList } from '@/services/api/requestApi';
import { useReq } from '@/services/net/request';
import { Button } from '@nextui-org/react';
import { SearchLinearIcon } from '@nextui-org/shared-icons';
import { useDebounce, useThrottle } from 'ahooks';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface CoinSelectPopupProps {
  onSelect?: (value: any) => void;
  defaultValue?: { from: any; reach: any };
  // 默认选中方向
  defaultDirection?: 'from' | 'reach';
  // 设置选中方向
  setDefaultDirection: any;
  // 选中币种
  setSelected: any;
  isOpen: boolean;
  onClose?: any;
  // 带有余额的币种列表
  walletList?: any;
}

export function CoinSelectPopup(props: CoinSelectPopupProps) {
  const {
    setSelected,
    defaultValue,
    defaultDirection = 'from',
    isOpen,
    onClose,
    setDefaultDirection,
    walletList,
  } = props;

  const { t } = useTranslation();

  const [params, setParams] = useState<any>({
    pageNo: 1,
  });

  const { data: list = [] as any, run: getList } = useReq(getAllCoinList, {
    manual: true,
    loadingDefault: false,
    onSuccess: () => {},
    onError: (e) => {},
    formatResult: (res: any) => {
      const dataList =
        res?.data.filter(
          (item: any) =>
            item?.walletType?.includes('1') || item?.walletType?.includes('2'),
        ) ?? [];
      return dataList;
    },
  });

  // 查询
  const [search, setSearch] = useState<string>();

  const debouncedSearch  = useDebounce(search, { wait: 500 });

  useEffect(() => {
    getList({ ...params, alias: debouncedSearch  });
  }, [debouncedSearch ]);

  // 获取余额
  const getBalance = (name: any) => {
    return walletList?.find((item: any) => item?.name === name);
  };

  // // 生成渲染列表
  // useEffect(() => {
  //   //先按排序字段sort排序
  //   //再按找获取的余额排序
  //   list
  //     ?.sort((a: any, b: any) => {
  //       return a.sort - b.sort;
  //     })
  //     .sort((a: any, b: any) => {
  //       return (
  //         getBalance(b.name)?.availableBalance -
  //         getBalance(a.name)?.availableBalance
  //       );
  //     });
  // }, [list]);

  // 当defaultDirection==reach 时 过滤掉from的币种
  const filterList = useMemo(() => {
    //先按排序字段sort排序
    //再按找获取的余额排序
    const sortList =  list
    ?.sort((a: any, b: any) => {
      return a.sort - b.sort;
    })
    .sort((a: any, b: any) => {
      return (
        getBalance(b.name)?.availableBalance -
        getBalance(a.name)?.availableBalance
      );
    })
    return sortList
      ?.filter((dt: any) =>
        defaultDirection === 'reach'
          ? dt.name !== defaultValue?.from?.name
          : dt,
      );
  }, [defaultDirection, list]);

  return (
    <BaseModal
      isOpen={isOpen}
      onClose={() => {
        onClose();
        // 清空搜索
        setSearch('');
      }}
    >
      <div className="flex flex-col max-h-[70vh] overflow-y-auto">
        <div className="flex-shrink-0 pt-4 w-full  bg-background rounded-t-[12px]">
          <div className="text-base text-center pb-3">{t('选择币对')}</div>
          <BaseFlex vertical className=" w-full px-2 ">
            <BaseFlex align="center" justify="around" vertical={false} flex={1}>
              <Button
                onClick={() => {
                  setDefaultDirection('from');
                  // 清空搜索
                  setSearch('');
                }}
                className={
                  defaultDirection === 'from'
                    ? ' bg-backgroundAuxiliaryColor w-36 h-14 border-1 border-primary'
                    : 'w-36 h-14  bg-background border-1 border-borderColor'
                }
              >
                <BaseFlex align="center" justify="center" vertical>
                  <BaseFlex align="center" justify="center" className="pb-1">
                    <span
                      className={
                        defaultDirection === 'from'
                          ? 'text-xs text-foreground'
                          : 'text-xs text-auxiliaryTextColor'
                      }
                    >
                      {t('从')}
                    </span>
                  </BaseFlex>
                  <BaseFlex align="center" justify="start">
                    <img
                      src={defaultValue?.from?.icon}
                      width={25}
                      className="rounded-lg"
                    />
                    <span
                      className={
                        defaultDirection === 'from'
                          ? 'text-foreground ml-1'
                          : 'text-auxiliaryTextColor ml-1'
                      }
                    >
                      {defaultValue?.from?.alias}
                    </span>
                  </BaseFlex>
                </BaseFlex>
              </Button>
              <Button
                isIconOnly
                color="primary"
                variant="faded"
                aria-label="Take a photo"
                className="border-0  bg-backgroundAuxiliaryColor rounded-full mx-[12px]"
              >
                <img src={right} className="mx-[12px]" onClick={() => {}} />
              </Button>
              <Button
                onClick={() => {
                  setDefaultDirection('reach');
                  // 清空搜索
                  setSearch('');
                }}
                className={
                  defaultDirection === 'reach'
                    ? 'bg-backgroundAuxiliaryColor w-36 h-14 border-1 border-primary'
                    : 'w-36 h-14 bg-background border-1 border-borderColor'
                }
              >
                <BaseFlex align="center" justify="center" vertical>
                  <BaseFlex align="center" justify="center" className="pb-1">
                    <span
                      className={
                        defaultDirection === 'reach'
                          ? 'text-xs text-foreground'
                          : 'text-xs text-auxiliaryTextColor'
                      }
                    >
                      {t('至')}
                    </span>
                  </BaseFlex>
                  <BaseFlex align="center" justify="start">
                    <img
                      src={defaultValue?.reach?.icon}
                      width={25}
                      className="rounded-lg"
                    />
                    <span
                      className={
                        defaultDirection === 'reach'
                          ? 'text-foreground ml-1'
                          : 'text-auxiliaryTextColor ml-1'
                      }
                    >
                      {defaultValue?.reach?.alias}
                    </span>
                  </BaseFlex>
                </BaseFlex>
              </Button>
            </BaseFlex>

            <BaseFlex align="center" justify="start" flex={1} className="pt-5">
              <BaseInput
                type="text"
                value={search}
                placeholder={t('搜索')}
                labelPlacement="outside"
                radius="md"
                className="!h-fit bg-background"
                classNames={{
                  inputWrapper: '!h-[40px]',
                }}
                startContent={
                  <SearchLinearIcon className="text-sm  text-default-400 pointer-events-none flex-shrink-0" />
                }
                onChange={(event) => {
                    setSearch(event.target.value);
                }}
              />
            </BaseFlex>
          </BaseFlex>
        </div>

        <div className="flex-1 pt-5 pb-[10px] px-3 overflow-y-auto  w-full flex">
          <div className="w-full">
            {filterList?.length !== 0 &&
              filterList?.map((item: any) => (
                <BaseFlex flex={1} className="py-1" key={item?.id}>
                  <XCard
                    align="center"
                    justify="flex-start"
                    className="bg-background"
                    onClick={() => {
                      if (defaultDirection === 'from') {
                        setSelected({
                          ...defaultValue,
                          from: item,
                        });
                        setDefaultDirection('reach');
                      } else {
                        setSelected({
                          ...defaultValue,
                          reach: item,
                        });
                        onClose();
                      }
                      // 选择后清空
                      setSearch('');
                    }}
                    title={
                      <div className="flex justify-between bg-background pr-1">
                        <BaseFlex
                          flex={1}
                          align="center"
                          justify="start"
                          className=" w-full flex-1 py-1 px-1"
                        >
                          <img
                            src={item?.icon}
                            width={30}
                            height={30}
                            className="rounded-lg"
                          />
                          <BaseFlex
                            flex={1}
                            align="center"
                            justify="start"
                            vertical
                            className="ml-1"
                          >
                            <BaseFlex
                              flex={1}
                              align="center"
                              justify="start"
                              className="font-bold text-titleColor text-[14px]"
                            >
                              {item?.alias}
                            </BaseFlex>

                            <BaseFlex
                              flex={1}
                              align="center"
                              justify="start"
                              className="text-xs text-auxiliaryTextColor"
                            >
                              {item?.alias}
                            </BaseFlex>
                          </BaseFlex>
                        </BaseFlex>
                        {defaultDirection === 'from' && (
                          <span>
                            {getBalance(item?.name)?.availableBalance}
                          </span>
                        )}
                      </div>
                    }
                  />
                </BaseFlex>
              ))}
          </div>
        </div>
      </div>
    </BaseModal>
  );
}
