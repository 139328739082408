import { HTMLProps, ReactNode, useMemo } from 'react';

export function XCard(props: XCardProps) {
  const {
    title,
    extra,
    padding = 'small',
    vertical = 'horizontal',
    justify = 'center',
    gap = 'small',
    wrap = 'wrap',
    align = 'center',
    className,
    radius = true,
    data = [],
    span = 4,
    description,
    footer,
    childPadding = 'px-3 py-2',
    skeletonLoading = false, // 新增的skeletonLoading属性，默认为false
    ...reset
  } = props;

  // 处理传递过来的padding
  const paddingClass = useMemo(() => {
    let paddingValue = 'p-1';
    if (padding === 'small') {
      paddingValue = 'p-1';
    } else if (padding === 'middle') {
      paddingValue = 'p-2';
    } else if (padding === 'large') {
      paddingValue = 'p-3';
    } else {
      paddingValue = padding;
    }
    return { className: true, padding: paddingValue };
  }, [padding]);

  // 处理布局
  const layoutClass = useMemo(() => {
    const layoutValue =
      vertical === 'vertical' ? 'flex flex-col' : 'flex flex-row';
    return { className: true, layout: layoutValue };
  }, [vertical]);

  // 处理布局
  const justifyClass = useMemo(() => {
    let justifyValue = 'justify-start';
    switch (justify) {
      case 'flex-start':
        justifyValue = 'justify-start';
        break;
      case 'flex-end':
        justifyValue = 'justify-end';
        break;
      case 'center':
        justifyValue = 'justify-center';
        break;
      case 'space-around':
        justifyValue = 'justify-around';
        break;
      case 'space-between':
        justifyValue = 'justify-between';
        break;
      case 'space-evenly':
        justifyValue = 'justify-evenly';
        break;
      default:
        justifyValue = 'justify-start';
        break;
    }
    return { className: true, justify: justifyValue };
  }, [justify]);

  // 处理间距
  const gapClass = useMemo(() => {
    let gapValue = 'gap-1';
    if (gap === 'small') {
      gapValue = 'gap-1';
    } else if (gap === 'middle') {
      gapValue = 'gap-2';
    } else if (gap === 'large') {
      gapValue = 'gap-3';
    }
    return { className: true, gap: gapValue };
  }, [gap]);

  // 处理换行
  const wrapClass = useMemo(() => {
    const wrapValue = wrap === 'wrap' ? 'flex-wrap' : 'flex-nowrap';
    return { className: true, wrap: wrapValue };
  }, [wrap]);

  // 处理布局
  const alignClass = useMemo(() => {
    let alignValue = 'items-center';
    switch (align) {
      case 'flex-start':
        alignValue = 'items-start';
        break;
      case 'flex-end':
        alignValue = 'items-end';
        break;
      case 'center':
        alignValue = 'items-center';
        break;
      default:
        alignValue = 'items-center';
        break;
    }
    return { className: true, align: alignValue };
  }, [align]);

  const spanClass = useMemo(() => {
    let spanValue = 'w-1/2';
    switch (span) {
      case 1:
        spanValue = 'w-full';
        break;
      case 2:
        spanValue = 'w-1/2';
        break;
      case 3:
        spanValue = 'w-1/3';
        break;
      case 4:
        spanValue = 'w-1/4';
        break;
      default:
        spanValue = 'w-1/2';
        break;
    }
    return { className: true, span: spanValue };
  }, [span]);

  // 数据融合
  const FusionClassName = useMemo(() => {
    const classNames = [
      paddingClass.padding,
      layoutClass.layout,
      justifyClass.justify,
      gapClass.gap,
      wrapClass.wrap,
      alignClass.align,
      className,
    ];
    return classNames?.filter((name) => name)?.join(' ');
  }, [
    paddingClass,
    layoutClass,
    justifyClass,
    gapClass,
    wrapClass,
    alignClass,
    className,
  ]);

  const renderSkeleton = useMemo(() => {
    if (skeletonLoading) {
      return (
        <div className={`skeleton-loader ${FusionClassName}`}>
          <div className="skeleton-loader-title"></div>
          <div className="skeleton-loader-description"></div>
          {data.map((item, index) => (
            <div
              key={index}
              className={`${spanClass.span} skeleton-loader-item ${childPadding}`}
            ></div>
          ))}
        </div>
      );
    }
    return null;
  }, [skeletonLoading, FusionClassName, spanClass, childPadding, data]);

  if (skeletonLoading) {
    return renderSkeleton;
  }

  const handleClick = (event: React.MouseEvent<HTMLTableRowElement>) => {
    const target = event.currentTarget;
    target.classList.add('bg-backgroundAuxiliaryColor');
    setTimeout(() => {
      target.classList.remove('bg-backgroundAuxiliaryColor');
    }, 200); // 显示背景色的时间
  };

  return (
    <div
      className={`shadow-lg ${
        radius ? 'rounded-md' : ''
      } w-full shadow-lg bg-auxiliaryTextColor ${className}`}
      {...reset}
    >
      <div
        className={`${FusionClassName.replace(layoutClass.layout, '')} flex`}
      >
        <div className="w-full">
          <div className="text-base w-full flex-1 rounded-md" onClick={handleClick}>
            {title}
          </div>
          <div className="text-xs">{description}</div>
        </div>
        {extra && <div>{extra}</div>}
      </div>
      <div className={`flex w-full ${wrapClass.wrap}`}>
        {data &&
          data.map?.(
            (item, index) =>
              item.label && (
                <div
                  key={index}
                  className={`${FusionClassName.replace(
                    paddingClass.padding,
                    '',
                  )} ${spanClass.span} ${childPadding}`}
                >
                  <div style={{ color: 'var(--nutui-gray-2)' }}>
                    {item.label}
                  </div>
                  <div>{item.value}</div>
                </div>
              ),
          )}
      </div>
      {footer && <div className={paddingClass.padding}>{footer}</div>}
    </div>
  );
}

export interface DataProps {
  /**
   * 标题
   * @param {string} label 标题
   */
  label: string;
  /**
   * 值
   * @param {string | number | ReactNode} value 值
   */
  value: string | number | ReactNode;
}

export interface XCardProps
  extends Partial<Omit<HTMLProps<any>, 'data' | 'title'>> {
  /**
   * 布局方式
   * @param {string} vertical 垂直布局
   */
  vertical?: 'vertical' | 'horizontal';
  /**
   * 布局方式
   * @param {string} justify 布局方式
   */
  justify?:
    | 'flex-start'
    | 'flex-end'
    | 'center'
    | 'space-around'
    | 'space-between'
    | 'space-evenly';
  /**
   * 布局方式
   * @param {string} align 布局方式
   */
  align?: 'flex-start' | 'flex-end' | 'center';
  /**
   * 间隙
   * @param {string} gap 间隙
   */
  gap?: 'small' | 'middle' | 'large';
  /**
   * 是否有换行
   * @param {string} wrap 是否有换行
   */
  wrap?: 'wrap' | 'nowrap';
  /**
   * 标题
   * @param {string} title 标题
   */
  title: string | number | ReactNode;
  /**
   * 副标题
   * @param {string | ReactNode} subTitle 副标题
   */
  extra?: string | ReactNode;
  /**
   * 描述
   * @param {string | ReactNode} description 描述
   */
  description?: string | ReactNode;
  /**
   * 内边距
   * @param {string | number} padding 内边距
   * @description 内边距 字符串则是根据tailwindcss中的尺寸来的，需要传入对应的尺寸 如：padding="small" 或者 p-1 p-3
   */
  padding?: string | 'small' | 'middle' | 'large';
  /**
   * 是否开启圆角
   * @param {boolean} radius 是否开启圆角
   */
  radius?: boolean;
  /**
   * 子元素padding
   * @param  {string} padding 子元素padding
   */
  childPadding?: string;
  /**
   * 一排能放置多少个
   * @param {number} span 一排能放置多少个
   */
  span?: 1 | 2 | 3 | 4;
  /**
   * 卡片底部元素
   * @param {ReactNode} footer 卡片底部元素
   */
  footer?: ReactNode;
  /**
   * 数据渲染
   * @param {DataProps[]} data 数据渲染
   */
  data?: DataProps[];
  /**
   * 是否开启骨架屏
   * @param {boolean} skeletonLoading 是否开启骨架屏
   */
  skeletonLoading?: boolean;
}
