import { LoadingSvg } from '@/assets/icons/comm/LoadingSvg';
import { MaterialSymbolsArrowBack } from '@/assets/icons/layout/MaterialSymbolsArrowBack';
import { BaseFlex } from '@/components/base/baseFlex';
import BaseModal from '@/components/base/baseModal';
import { createCoinTrade, getCoinTradeDetail } from '@/services/api/requestApi';
import { useReq } from '@/services/net/request';
import RenderUtil from '@/utils/RenderUtil';
import { Button } from '@nextui-org/react';
import { useDebounceEffect } from 'ahooks';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from '@/layouts/components/ToastMessage';

type MarketPriceEnterOrderProps = {
  defaultValue: any;
  onSuccess: () => void;
  open: boolean;
  onClose: any;
};

export function MarketPriceEnterOrder(props: MarketPriceEnterOrderProps) {
  const { defaultValue, onSuccess, open, onClose } = props;

  const { t } = useTranslation();

  // loading
  const [loading, setLoading] = useState(false);

  const [progress, setProgress] = useState(0);

  // 下面 1btc = xxx 请求接口
  const {
    data: detailData = {} as any,
    runAsync: getCoinDetail,
    loading: queryLoading,
  } = useReq(
    (params: any) => {
      if (defaultValue?.value?.from) {
        return getCoinTradeDetail(params);
      }
      return null;
    },
    {
      manual: true,
      formatResult: (res: any) => {
        return res?.data ?? {};
      },
    },
  );

  useDebounceEffect(
    () => {
      if (defaultValue?.value?.from) {
        getCoinDetail({
          sourceCoinId: defaultValue?.from?.id,
          targetCoinId: defaultValue?.reach?.id,
          amount: defaultValue?.value?.from,
          type: 1,
        });
      }
    },
    [defaultValue?.value?.from], // 依赖数组
    { wait: 500 }, // 配置防抖时间，单位是毫秒
  );

  useEffect(() => {
    if (detailData && Object.keys(detailData).length > 0) {
      const interval = setInterval(() => {
        setProgress((v) => (v >= 100 ? 0 : v + 1));
      }, 100);

      return () => clearInterval(interval);
    }
  }, [detailData]);

  useEffect(() => {
    if (
      progress === 100 &&
      Object.keys(defaultValue?.from).length > 0 &&
      defaultValue?.value?.from
    ) {
      getCoinDetail({
        sourceCoinId: defaultValue?.from?.id,
        targetCoinId: defaultValue?.reach?.id,
        amount: defaultValue?.value?.from,
        type: 1,
      });
    }
  }, [progress]);
  return (
    <BaseModal
      classNames={{ modal: ' max-h-[80vh] overflow-y-auto px-4 pb-3' }}
      isOpen={open}
      onClose={() => {
        onClose && onClose();
      }}
      title={t('确定订单')}
    >
      <BaseFlex vertical className="px-[80px]">
        <BaseFlex align="center" justify="between" flex={1}>
          <div>
            <img
              src={defaultValue?.from?.icon}
              width={30}
              className="rounded-lg"
            />
          </div>
          <div className="text-auxiliaryTextColor mx-10">
            <MaterialSymbolsArrowBack className="rotate-180" />
          </div>
          <div>
            <img
              src={defaultValue?.reach?.icon}
              width={30}
              className="rounded-lg"
            />
          </div>
        </BaseFlex>
        <BaseFlex align="center" justify="between" flex={1} className="py-2">
          <div className="text-auxiliaryTextColor ml-2">{t('从')}</div>
          <div className="mx-12"></div>
          <div className="text-auxiliaryTextColor">{t('收到')}</div>
        </BaseFlex>
      </BaseFlex>
      <BaseFlex
        align="center"
        justify="between"
        flex={1}
        className="px-[25px] "
      >
        <div className="text-titleColor text-center flex-1">
          {defaultValue?.value?.from} {defaultValue?.from?.alias}
        </div>
        <div className="mx-6"></div>
        <div className="text-titleColor text-center flex-1">
          {isNaN(defaultValue?.value?.from * detailData?.rate)
            ? 0
            : RenderUtil.FormatAmount(
                defaultValue?.value?.from * detailData?.rate - detailData?.fee,
                8,
              )}{' '}
          {defaultValue?.reach?.alias}
        </div>
      </BaseFlex>
      <hr className="my-1 text-borderColor" />
      <BaseFlex align="center" justify="between" flex={1} className="py-3">
        <span className="text-auxiliaryTextColor">{t('交易手续费')}</span>
        <span className="text-titleColor">
          {RenderUtil.FormatAmount(detailData?.fee, 8)}
          {' ' + defaultValue?.reach?.alias}
        </span>
      </BaseFlex>
      <BaseFlex align="center" justify="between" flex={1} className="py-3">
        <span className="text-auxiliaryTextColor">{t('汇率')}</span>
        <BaseFlex align="center" justify="end" flex={1}>
          <span className="text-titleColor">
            1{defaultValue?.from?.alias} ≈{' '}
            {isNaN(detailData?.rate)
              ? 0
              : RenderUtil.FormatAmount(detailData?.rate, 8)}{' '}
            {defaultValue?.reach?.alias}
          </span>
        </BaseFlex>
      </BaseFlex>
      <BaseFlex align="center" justify="center" flex={1} className="mt-6 pb-3">
        <Button
          isLoading={loading || queryLoading}
          spinner={<LoadingSvg />}
          className="w-full bg-primary text-backgroundAuxiliaryColor"
          radius="sm"
          onClick={async () => {
            setLoading(true);
            const response = await createCoinTrade({
              sourceCoinId: defaultValue?.from?.id,
              targetCoinId: defaultValue?.reach?.id,
              amount: defaultValue?.value?.from,
              type: 1,
            }).finally(() => setLoading(false));
            if (response?.code === 0) {
              toast.success(response?.msg);
            }
            onSuccess && onSuccess();
          }}
        >
          {t('确定')}
        </Button>
      </BaseFlex>
    </BaseModal>
  );
}
